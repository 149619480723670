import { install } from "./pendo.install";

const getAPIKey = env => {
  switch (env) {
    case "production":
      return "0bf9feab-a219-417f-515f-391beeeb147d";
    default:
      return "7867cec5-14f2-493b-6019-20ce71bec84e";
  }
};

let _pendo;

const isPendoLoaded = () => {
  return "undefined" !== typeof _pendo;
};

export const getPendo = env => {
  if (!isPendoLoaded()) {
    _pendo = install(getAPIKey(env));
  }

  return _pendo;
};
