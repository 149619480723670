import { getPendo } from "./pendo.repository";

export default {
  install: function (env) {
    const pendo = getPendo(env);

    return {
      initialize: function (id, email, internal) {
        pendo.initialize({
          disablePersistence: true,
          visitor: { email, id, internal },
        });

        return new PendoUser(pendo);
      },
    };
  },
};

export function PendoUser(pendo) {
  this.updateViewingCompanyWithRole = function (company, role) {
    const companyWithRole = company + "::" + role;
    pendo.updateOptions({
      visitor: {
        viewingCompanies: [company],
        viewingCompaniesWithRole: [companyWithRole],
      },
      account: {
        id: company,
      },
    });
    return this;
  };

  this.updatePendoOptions = function (company, companyRole, companyType, navStatus, userLanguage) {
    pendo.updateOptions({
      visitor: {
        viewingCompanies: [company],
        companyRole: companyRole,
        companyType: companyType,
        navStatus: navStatus,
        userLanguage: userLanguage,
      },
      account: {
        id: company
      }
    });
    return this;
  };

  this.updateViewingCompanies = function (companies) {
    const options = {
      visitor: {
        viewingCompanies: companies,
      },
    };

    if (companies.length === 1) {
      options["account"] = {
        id: companies[0],
      };
    }

    pendo.updateOptions(options);
    return this;
  };

  this.updateLanguage = function (language) {
    pendo.updateOptions({
      visitor: {
        userLanguage: language,
      },
    });
    return this;
  };
}
